import React from 'react';
import ReactDOM from 'react-dom/client';
import App  from './components/app/App';
import { BrowserRouter } from 'react-router-dom';
import "normalize.css";
import './index.css';
import './adaptive.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App/>
    </BrowserRouter>
);

