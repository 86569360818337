import "./contactsPage.sass"

const Contacts = () => {
  return (
    <div className="center_container">
      <div className="middle__block"></div>
    </div>
  )
}

export default Contacts;